var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exam-group-adding"},[_c('v-container',[_c('ValidationObserver',{ref:"form_create_group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"form_create_group"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitGroupQuestion)}}},[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Chọn loại nhóm câu hỏi")])]),(_vm.group_question)?_c('v-col',{staticClass:"group-type-checkbox",attrs:{"cols":"10"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.group_question_type_id),expression:"group_question.group_question_type_id"}],staticClass:"mr-1",attrs:{"type":"radio","id":"general","value":"1"},domProps:{"checked":_vm._q(_vm.group_question.group_question_type_id,"1")},on:{"change":function($event){return _vm.$set(_vm.group_question, "group_question_type_id", "1")}}}),_c('label',{staticClass:"mr-1",attrs:{"for":"general"}},[_vm._v("Nhóm chung")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.group_question_type_id),expression:"group_question.group_question_type_id"}],staticClass:"mr-1",attrs:{"type":"radio","id":"custom","value":"2"},domProps:{"checked":_vm._q(_vm.group_question.group_question_type_id,"2")},on:{"change":function($event){return _vm.$set(_vm.group_question, "group_question_type_id", "2")}}}),_c('label',{staticClass:"mr-1",attrs:{"for":"custom"}},[_vm._v("Nhóm riêng")])]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_vm._v("Nhập tiêu đề nhóm câu hỏi thi")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('CkContent',{attrs:{"isValidate":_vm.isValidate,"content":_vm.group_question.title},on:{"getData":_vm.getData}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.CkMessage)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('p',[_vm._v("Từ câu ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.from),expression:"group_question.from"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.group_question.from)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.group_question, "from", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('p',[_vm._v("Đến câu")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.to),expression:"group_question.to"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.group_question.to)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.group_question, "to", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),((_vm.exam_config_id == _vm.EXAM_CONFIG_ID.topicI_listening ||_vm.exam_config_id == _vm.EXAM_CONFIG_ID.topicII_listening))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pl-1 upload-file-input",attrs:{"id":"fileUpload","prepend-icon":"mdi-soundcloud","label":"Upload File Audio"},on:{"change":_vm.onChangeFileUpload},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.audio_message)?_c('span',{staticClass:"validate-error text-danger text-danger",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.audio_message)+" ")]):_vm._e()]}}],null,true)}),(_vm.group_question.audio)?_c('audio',{ref:"audio",staticClass:"audio-block",attrs:{"controls":"controls"}},[_c('source',{attrs:{"src":_vm.group_question.audio && _vm.group_question.audio.src,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()],1)],1):_vm._e(),_c('v-row',{staticClass:"text-center"},[(_vm.checkMessage)?_c('v-col',[_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.checkMessage)+" ")])]):_vm._e(),(_vm.error_msg)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.error_msg)+" ")])]):_vm._e(),_c('v-col',[_c('v-btn',{attrs:{"type":"submit","form":"form_create_group","color":"primary"}},[_vm._v("Cập nhật")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }