<template>
  <div class="exam-group-adding">
    <v-container>
      <ValidationObserver ref="form_create_group" v-slot="{ handleSubmit }">
        <v-form
            @submit.prevent="handleSubmit(submitGroupQuestion)"
            method="post"
            id="form_create_group"
        >
          <v-row>
            <v-col>
              <p>Chọn loại nhóm câu hỏi</p>
            </v-col>
            <v-col v-if="group_question" class="group-type-checkbox" cols="10">
              <input class="mr-1" type="radio" id="general" value="1" v-model="group_question.group_question_type_id">
              <label class="mr-1" for="general">Nhóm chung</label>
              <input class="mr-1" type="radio" id="custom" value="2" v-model="group_question.group_question_type_id">
              <label class="mr-1" for="custom">Nhóm riêng</label>
            </v-col>
          </v-row>
          <!--          title field -->
          <v-row>
            <v-col cols="4">
              <div>Nhập tiêu đề nhóm câu hỏi thi</div>
            </v-col>
            <v-col cols="8">
              <CkContent
                  :isValidate="isValidate"
                  :content="group_question.title"
                  @getData="getData"
              ></CkContent>
              <div class="validate-error text-danger text-danger">
                {{ CkMessage }}
              </div>
            </v-col>
          </v-row>
          <!--          from field-->
          <v-row>
            <v-col cols="2">
              <p>Từ câu </p>
            </v-col>
            <v-col cols="10">
              <ValidationProvider rules="required|number" v-slot="{ errors }">
                <input v-model="group_question.from" class="number-input" type="text">
                <div class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <!--          to field-->
          <v-row>
            <v-col cols="2">
              <p>Đến câu</p>
            </v-col>
            <v-col cols="10">
              <ValidationProvider rules="required|number" v-slot="{ errors }">
                <input v-model="group_question.to" class="number-input" type="text">
                <div class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row
              v-if="(exam_config_id == EXAM_CONFIG_ID.topicI_listening ||exam_config_id == EXAM_CONFIG_ID.topicII_listening)">
            <v-col cols="12">
              <ValidationProvider
                  name="file"
                  v-slot="{ errors }"
              >
                <v-file-input
                    class="pl-1 upload-file-input"
                    id="fileUpload"
                    prepend-icon="mdi-soundcloud"
                    v-model="file"
                    label="Upload File Audio"
                    @change="onChangeFileUpload"
                ></v-file-input>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
                <span style="display: block" v-if="audio_message" class="validate-error text-danger text-danger">
                  {{ audio_message }}
                </span>
              </ValidationProvider>
              <audio class="audio-block" ref="audio" v-if="group_question.audio" controls="controls">
                <source :src="group_question.audio && group_question.audio.src" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col v-if="checkMessage">
          <span class="validate-error text-danger text-danger">
              {{ checkMessage }}
          </span></v-col>
            <v-col cols="12" v-if="error_msg">
          <span class="validate-error text-danger text-danger">
              {{ error_msg }}
          </span></v-col>
            <v-col>
              <v-btn type="submit" form="form_create_group" color="primary">Cập nhật</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import {
  GET_GROUP_QUESTION_DETAIL,
  SAVE_GROUP_QUESTION,
  UPDATE_GROUP_QUESTION,
  DELETE_GROUP_QUESTION
} from "@/store/group-questions.module";
import CkContent from "@/components/ck-content";
import {
  LIST_GROUP_QUESTION_BY_TYPE,
} from "@/store/group-questions.module";
import {UPLOAD_FILE} from "@/store/exams.module";
import {EXAM_CONFIG_ID, EXAM_TYPE} from "@/common/constants";

export default {
  components: {
    CkContent,
  },
  data() {
    return {
      EXAM_CONFIG_ID: EXAM_CONFIG_ID,
      exam_config_id: this.$route.query.exam_config_id,
      group_question_id: this.$route.query.group_question_id,
      file: null,
      audio: {},
      headers: [
        {text: "Chọn nhóm câu hỏi", value: "isSelected"},
        {text: "Từ câu", value: "from"},
        {text: "Đến câu", value: "to"},
        {text: "Tên nhóm", value: "title"},
        {text: "Thao tác", value: "actions", sortable: false},
      ],
      audio_message: "",
      checkMessage: "",
      CkMessage: "",
      error_msg: "",
      EXAM_TYPE: EXAM_TYPE,
      groupType: 'general',
      isValidate: true,
    };
  },
  computed: {
    ...mapGetters({
      group_question: "groupQuestionDetail",
    }),
    isListeningExam() {
      return (this.exam_config_id == EXAM_CONFIG_ID.topicI_listening || this.exam_config_id == EXAM_CONFIG_ID.topicII_listening);
    }
  },
  watch: {
    audio_message: {
      immediate: true,
      handler(self) {
        self && setTimeout(() => {
          this.audio_message = ""
        }, 1000)
      },
    },
    error_msg: {
      immediate: true,
      handler(self) {
        self && setTimeout(() => {
          this.error_msg = ""
        }, 1000)
      },
    }
  },
  async created() {
    await this.$store.dispatch(GET_GROUP_QUESTION_DETAIL, {
      group_question_id: this.group_question_id,
    });
    await this.reloadAudio();
    this.group_question.exam_config_id = this.exam_config_id;
  },
  methods: {
    reloadAudio() {
      let vid = this.$refs.audio;
      if (vid) {
        vid.load();
      }
    },
    fileMp3Validation() {
      if (!this.isListeningExam) {
        return true;
      }
      var fileInput =
          document.getElementById('fileUpload');
      var filePath = fileInput.value;
      // Allowing file type
      var allowedExtensions =
          /(\.mp3)$/i;
      if (!allowedExtensions.exec(filePath)) {
        fileInput.value = '';
        return false;
      }
      return true;
    },
    onChangeFileUpload() {
      if (!this.fileMp3Validation()) {
        this.audio_message = "File tải lên không đúng định dạng mp3!";
        return null;
      }
      let formData = new FormData();
      formData.append("upload", this.file);
      this.$store.dispatch(UPLOAD_FILE, formData).then((data) => {
        if (data.status) {
          if (!this.group_question.audio) {
            this.group_question.audio = {
              src: ""
            }
          }
          this.group_question.audio.src = data.data;
          this.reloadAudio();
        }
      });
    },
    formatGroupQuestionList(groupQuestions) {
      groupQuestions.forEach(el => {
        if (el.id === this.group_question.id) {
          el.isSelected = true;
        }
      })
      this.$forceUpdate();
    },
    isNull(title) {
      if (!title) return true;
    },
    submitGroupQuestion() {
      if (!this.fileMp3Validation() && this.file) {
        this.audio_message = "File tải lên không đúng định dạng mp3!";
        return null;
      }
      if (parseInt(this.group_question.from) >= parseInt(this.group_question.to)) {
        this.error_msg = "Câu hỏi bắt đầu phải nhỏ hơn câu hỏi kết thúc"
        return null;
      }
      if (this.group_question_id) {
        this.$store.dispatch(UPDATE_GROUP_QUESTION, {
          group_question: this.group_question
        }).then(data => {
          if (data.status) {
            this.backToExam()
          }
        });
      } else {
        this.$store.dispatch(SAVE_GROUP_QUESTION, {
          group_question: this.group_question
        }).then(data => {
          if (data.status) {
            this.backToExam()
          }
        });
      }
    },

    editItem(item) {
      this.editedIndex = this.groupQuestions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.confirmDelete(item);
    },
    confirmDelete(item) {
      this.$store.dispatch(DELETE_GROUP_QUESTION, item.id).then(data => {
        if (data.status) {
          this.fetchGroupQuestionBy(1, this.exam.exam_config_id);
        }
      });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    switchType() {
      this.group_question.group_question_type_id = this.selectType
      // type = general group
      if (this.selectType === 1) {
        this.fetchGroupQuestionBy(this.selectType, this.exam.exam_config_id)
      }
    },
    fetchGroupQuestionBy(type, exam_config_id) {
      this.$store.dispatch(LIST_GROUP_QUESTION_BY_TYPE, {
        type_id: type,
        exam_config_id: exam_config_id
      });
    },
    getData(value) {
      this.group_question.title = value;
    },
    backToExam() {
      this.$router.push({
        path: "/group-question/list",
        query: {exam_config_id: this.exam_config_id},
      });
    },
  },

}
;
</script>

<style lang="scss">
.exam-group-adding {
  font-size: 16px;
}

.number-input {
  width: 40px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
}

.group-type-checkbox {
  display: flex;
}
</style>
